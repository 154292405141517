import axios from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../../../utils/http';

async function verifyLoginMutation({ userId, token, password }) {
  return axios.post(`${API}/users/${userId}/verify`, {
    token,
    password,
  });
}

export const verifyLoginUseCase = () => {
  const { isSuccess, data, isLoading, error, mutate } = useMutation((e) =>
    verifyLoginMutation(e),
  );

  return {
    isSuccessVerifyLoginUseCase: isSuccess,
    dataVerifyLoginUseCase: data?.data,
    isLoadingVerifyLoginUseCase: isLoading,
    errorVerifyLoginUseCase: error,
    mutateVerifyLogin: mutate,
  };
};
