import axios from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../../../utils/http';
import { removeAccessToken } from '../../../../../utils/localStorage';

async function signInMutation({ email, password }) {
  return axios.post(`${API}/users/signin`, {
    email,
    password,
  });
}

export const signInUseCase = () => {
  const { isSuccess, data, isLoading, error, mutate } = useMutation(
    (e) => signInMutation(e),
    {
      onSuccess: (data) => {
        removeAccessToken();
      },
    },
  );

  return {
    isSuccessSignInUseCase: isSuccess,
    dataSignInUseCase: data?.data,
    isLoadingSignInUseCase: isLoading,
    errorSignInUseCase: error,
    mutateSignIn: mutate,
  };
};
